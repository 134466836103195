<template>
    <zw-sidebar @shown="shown" :title="$t('common.form.title.customer_reports')">

        <template v-if="!loading">
            <b-row>
                <b-col cols="7">
                </b-col>
                <b-col>
                    <b-row>
                        <b-col>
                            <table-pagination v-model="tableOptions"
                                              :refresh="refreshTable"
                                              table="crmCustomerReports"
                                              :filter="filter"
                                              :selected="selected"
                            ></table-pagination>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-table ref="table"
                     id="table"
                     class="zw-sticky"
                     responsive
                     striped
                     hover
                     :items="myProvider"
                     :fields="fields"
                     :sort-by.sync="tableOptions.sortBy"
                     :sort-desc.sync="tableOptions.sortDesc"
                     sort-icon-right
                     :current-page="tableOptions.currentPage"
                     :per-page="tableOptions.perPage"
                     no-provider-sorting
                     custom-foot
                     head-row-variant="info"
                     foot-row-variant="info"
            >
                <template #head()="data">
                    <span v-if="data.column.startsWith('_custom_')">{{ data.label }}</span>
                    <span v-else-if="data.label">{{ $t('common.column.sale.' + data.field.key) }}</span>
                </template>

                <template #cell(total)="row">
                    <b-button @click="openReport(row.item.id)"
                              v-if="$root.hasAccess('crm-customer-reports','update')"
                              variant="warning"
                              size="sm"
                              class="mr-1"
                    >
                        <b-icon icon="pencil-square" variant="light"></b-icon>
                    </b-button>
                    <b-button @click="deleteReport(row.item.id)"
                              v-if="$root.hasAccess('crm-customer-reports','delete')"
                              variant="danger"
                              size="sm"
                              class="mr-1"
                    >
                        <b-icon icon="trash" variant="light"></b-icon>
                    </b-button>
                </template>
            </b-table>


            <b-row align-h="end">
                <b-col sm="3">
                    <b-button block
                              v-if="$root.hasAccess('crm-customer-reports','create')"
                              @click="openReport(null)"
                              type="button"
                              variant="primary"
                    >
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>
        </template>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'customer-reports',
    mixins: [commonTable],
    data() {
        return {
            payload: {},
            loading: true,
            saveFilters: true,
        }
    },
    computed: {
        items: function () {
            return this.getReportsTable().data
        }
    },
    methods: {
        ...mapGetters('CustomerReports', ['getReportsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CustomerReports/fetchReports', 'getReportsTable');
        },
        ctxBaseParams() {
            return {
                'customerId': this.payload.customerId
            }
        },
        deleteReport(reportId) {
            this.$removeConfirm('CustomerReports/deleteReport', {
                id: reportId,
                customerId: this.payload.customerId
            }, this.refreshTable)
        },
        shown() {
            this.loading = false;
        },
        openReport(reportId) {
            this.$root.$children[0].openModal('customer-report-modal', {
                id: reportId,
                customerId: this.payload.customerId,
            }, this.refreshTable)
        }
    }
}
</script>